.headshots {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-flow: wrap;
  a {
    text-align:left;
    position: relative;
    text-decoration: none;
    font-weight: bold;
    display: block;
    margin-top: 1em;
    width: 175px;
    height: 175px;
    font-size: small;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;

    &:hover, &:visited, &:focus {
      color: #2a7ae2;
    }

    span {
      position: relative;
      bottom: 1.5em;
    }

  }
}
