@use "minima";
@use "contactform";
@use "headshots";



.footer-col-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &::after {
    display: none;
  }
}

a {
  font-weight: bold;
}
