
$itemcolor: rgb(0,124,137);
$textcolor: rgb(36, 28, 21);

#contactform {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: $textcolor;

  * {
    box-sizing: border-box;
  }

  img {
    width: 300px;
    height: 150px;
  }
  label {
    display: block;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 2px;
    color: inherit;
  }
  input, textarea {
    color: inherit;
    font: inherit;
    padding: 3px 8px;
    border-radius: 0;
    border-width: 1px;
    width: 100%;

    &:focus {
      outline: 2px solid $itemcolor;
      outline-offset: 1px;
      box-shadow: inset -2px 0 transparent;
    }
  }
  input {
    display: inline-block;
  }
  input#subscribe {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
  input[type="email"]:invalid:not(:focus):not(:placeholder-shown) ~ .requirements {
    margin-top: 5px;
    max-height: 200px;
    padding: 5px 30px 5px 50px;
    border-top: 1px dashed #aaa;
    background-color: whitesmoke;
  }
  .requirements {
  	padding: 0 30px 0 50px;
  	color: #C73030;
  	max-height: 0;
  	transition: 0.28s;
  	overflow: hidden;
  	font-style: italic;
  	font-size: 0.8em;
  }

  div#message {
    position: relative;
    margin-top: 10px;

    label {
      display: inline-block;
      margin: 0;
      margin-bottom: 2px;
      position: relative;
      left: 0;

    }
    #counter {
        font-size: 14px;
        font-weight: 400;
        color: #aaa;
        float: right;
        visibility: hidden;
        position: relative;
        right: 0;
        top: 3px;
    }
  }
  label[for=subscribe] {
    display: inline-block;
    font-weight: 400;
    font-size: inherit;
  }
  button {
    display: block;
    background: $itemcolor;
    color: white;
    padding: 10px 24px;
    margin-top: 24px;
    font-weight: bold;
    font-size: large;
  }
  textarea {
    display: block;
    resize: none;
  }

  .h-captcha {
    margin-top: 1em;
    text-align: center;
  }
}
